import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";

import { Flex } from "../Flex";
import { Link } from "../Link";

import { MenuListProps, MenuItemProps, DropdownProps } from "./NavMenu.types";

export const Wrapper = styled(Flex)`
  height: 100%;
  position: initial;
  align-items: center;
`;

export const NavContainer = styled.nav`
  height: 100%;

  @media screen and (max-width: 1150px) {
    display: none;
  }
`;

export const MenuItem = styled.li<MenuItemProps>`
  margin: 0;
  padding: 8px 24px;
  font-size: 16px;
  letter-spacing: 0.01em;
  font-weight: 500;
  height: 100%;
  display: flex;
  align-items: center;

  & > a {
    color: ${({ theme, isSale }): string =>
      isSale ? theme.colors.lime : theme.colors.white};
    text-decoration: none;
    transition: 0.3s ease opacity;

    :hover {
      opacity: 0.5;
    }
  }

  :hover > div {
    display: ${({ isClosed }): string => (isClosed ? "none" : "flex")};
  }

  @media screen and (max-width: 1440px) {
    padding: 8px 14px;
  }
`;

export const MenuList = styled.ul<MenuListProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style-type: none;
  height: 100%;

  ${({ child }): FlattenInterpolation<ThemeProps<DefaultTheme>> | false =>
    !!child &&
    css`
      ${MenuItem}:not(:nth-child(${child})) {
        opacity: 0.5;
      }
    `};
`;

export const MenuLink = styled(Link)`
  line-height: 1.3;

  @media screen and (max-width: 1350px) {
    font-size: 14px;
  }
`;

export const Dropdown = styled.div<DropdownProps>`
  position: absolute;
  height: auto;
  background-color: ${({ theme }): string => theme.colors.white};
  z-index: 105;
  padding: 40px;
  left: 0;
  right: 0;
  display: none;
  top: 70px;
  align-items: center;
  justify-content: center;
`;
