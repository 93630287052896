import { AxiosResponse } from "axios";

import { API } from "src/constants";
import { NewCheckoutItemData, NewCheckoutItemDataWithoutUuid } from "src/types";

import { Http } from "../http";

import {
  CheckoutResponse,
  CouponResponse,
  AddToCheckoutWithoutUuidResponse,
} from "./checkout.types";

export const getCheckout = (
  checkoutId: string,
  no_stocks?: boolean
): CheckoutResponse =>
  Http.get(`${API}/basket/${checkoutId}/get${no_stocks ? "?no_stocks=1" : ""}`);

export const addToCheckout = (
  checkoutId: string,
  data: NewCheckoutItemData
): Promise<AxiosResponse> =>
  Http.put(`${API}/basket/${checkoutId}/add`, data, {
    headers: { "Content-Type": "application/json" },
  });

export const addToCheckoutWithoutUuid = (
  data: NewCheckoutItemDataWithoutUuid
): AddToCheckoutWithoutUuidResponse =>
  Http.put(`${API}/basket/add`, data, {
    headers: { "Content-Type": "application/json" },
  });

export const removeFromCheckout = (
  checkoutId: string,
  id: number
): Promise<AxiosResponse> =>
  Http.delete(`${API}/basket/${checkoutId}/${id}/delete`);

export const changeCountInCheckout = (
  checkoutId: string,
  id: number,
  quantity: number
): Promise<AxiosResponse> =>
  Http.post(
    `${API}/basket/${checkoutId}/${id}/change`,
    { id, quantity },
    {
      headers: { "Content-Type": "application/json" },
    }
  );

export const checkCoupon = (
  checkoutId: string,
  coupon: string
): CouponResponse =>
  Http.post(`${API}/basket/${checkoutId}/coupon`, { coupon });
