import Headroom from "react-headroom";
import styled from "styled-components";

import { Box } from "../Box";
import { Flex } from "../Flex";
import { IconButton } from "../IconButton";
import { Icons } from "../Icons";
import { Link } from "../Link";

import { HeaderProps } from "./Header.types";

export const Wrapper = styled(Box)`
  width: 100%;
`;

export const FlexMenu = styled(Flex)`
  @media screen and (max-width: 767px) {
    margin-right: 5px;
  }
`;

export const MenuContainer = styled(Flex)`
  align-items: center;
  position: initial;
  height: 100%;

  @media screen and (max-width: 767px) {
    margin-left: 5px;
  }
`;

export const Banner = styled(Box)`
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  padding: 0 40px;
  background-color: ${({ theme }): string => theme.colors.white};
  overflow: hidden;
  transition: margin-top 0.2s ease-in-out 0s;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;

  p {
    margin: 0;
  }

  @media screen and (max-width: 1150px) {
    font-size: 10px;
  }
`;

export const HeaderContainer = styled.header<HeaderProps>`
  position: relative;
  width: 100%;
  background-color: ${({ theme }): string => theme.colors.black};
  height: 70px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1150px) {
    padding: 0 12px;
    height: 70px;
  }

  @media screen and (max-width: 480px) {
    padding: 0 8px;
  }
  .logo-svg {
    margin: 0 auto;
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1150px) {
    display: none;
  }
`;

export const MobileLogoLink = styled(LogoLink)`
  display: none;

  @media screen and (max-width: 1150px) {
    display: flex;
  }
`;

export const Logo = styled(Icons.Logo)`
  fill: ${({ theme }): string => theme.colors.white};
`;

export const MobileLogo = styled(Logo)`
  display: none;

  @media screen and (max-width: 1150px) {
    display: block;
    width: 68px;
    height: 24px;
    margin-left: 97px;
  }
  @media screen and (max-width: 767px) {
    margin-left: 11px;
  }
`;

export const Phone = styled.a`
  font-size: 18px;
  line-height: 20px;
  color: ${({ theme }): string => theme.colors.white};
  margin: 0;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    color: ${({ theme }): string => theme.colors.grey};
  }
  @media screen and (max-width: 1350px) {
    font-size: 16px;
  }
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

export const MobilePhone = styled.a`
  display: none;

  :hover {
    opacity: 0.5;
    text-decoration: none;
  }

  @media screen and (max-width: 1150px) {
    display: flex;
    align-items: center;
    margin-left: 24px;
  }
`;

export const PhoneIcon = styled(Icons.Phone)`
  width: 22px;
  height: 22px;
  fill: ${({ theme }): string => theme.colors.white};
`;

export const UserLink = styled(Link)`
  display: flex;

  padding: 6px 8px;
  margin-left: 68px;

  :hover {
    opacity: 0.5;
  }
  @media screen and (max-width: 1200px) {
    margin-left: 10px;
  }
  @media screen and (max-width: 1150px) {
    margin-left: 0;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const UserIcon = styled(Icons.User)`
  width: 20px;
  height: 20px;
  stroke: ${({ theme }): string => theme.colors.white};
  stroke-width: 2;
`;
export const UserIconLog = styled(Icons.UserLog)`
  width: 20px;
  height: 20px;
  stroke: ${({ theme }): string => theme.colors.white};
  stroke-width: 2;
`;
export const SearchButton = styled(IconButton)`
  margin-left: 10px;

  :hover {
    opacity: 0.5;
  }
`;

export const SearchIcon = styled(Icons.Search)`
  width: 20px;
  height: 20px;
  fill: ${({ theme }): string => theme.colors.white};
`;

export const CheckoutButton = styled(IconButton)`
  margin-left: 10px;

  :hover {
    opacity: 0.5;
  }
`;

export const CheckoutIcon = styled(Icons.Checkout)`
  width: 21px;
  height: 21px;
  fill: ${({ theme }): string => theme.colors.white};
`;

export const FavoriteLink = styled(Link)`
  display: flex;
  position: relative;
  padding: 6px 8px 2px;
  margin-left: 10px;

  :hover {
    opacity: 0.5;
  }

  span {
    top: -9px;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const FavoriteIcon = styled(Icons.Heart)`
  width: 24px;
  height: 21px;
  stroke: ${({ theme }): string => theme.colors.white};
  stroke-width: 2;
`;

export const ItemsCount = styled.span`
  display: flex;
  justify-content: center;
  position: absolute;
  top: -5px;
  right: -6px;
  width: 25px;
  height: 25px;
  border: 2px solid ${({ theme }) => theme.colors.black};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black};
`;

export const MenuButton = styled(IconButton)`
  display: none;

  :hover {
    opacity: 0.5;
  }

  @media screen and (max-width: 1150px) {
    display: flex;
  }
`;

export const BurgerIcon = styled(Icons.Burger)`
  fill: ${({ theme }): string => theme.colors.white};
`;

export const HeadroomWrapper = styled(Headroom)`
  height: auto !important;
  .headroom {
    height: 105px;
    position: ${({ disable }) => (disable ? "absolute" : "fixed")} !important;
    top: 0 !important;
    margin-top: ${({ disable }) => (disable ? "-105px" : "0px")};
    z-index: 100 !important;
    transition: ${({ disable }) =>
      disable ? "none" : "all 0.4s ease-in 0s, margin-top 0s"} !important;
  }
`;

export const UserBonuses = styled.div`
  margin-left: 6px;
  margin-right: 16px;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const UserBonusesTitle = styled.p`
  padding: 0;
  margin-top: 0;
  margin-bottom: 2px;

  font-size: 10px;
  line-height: 10px;
  color: rgba(255, 255, 255, 0.5);
`;

export const UserBonusesValue = styled.p`
  padding: 0;
  margin: 0;

  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
`;

export const PhonePicto = styled.a`
  display: none;
  padding: 8px;
  :hover {
    opacity: 0.5;
    text-decoration: none;
  }

  @media screen and (max-width: 1200px) {
    display: flex;
    align-items: center;
    margin-left: 24px;
  }
  @media screen and (max-width: 1150px) {
    display: none;
  }
`;
