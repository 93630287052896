import React, { FC } from "react";
import { useSelector } from "react-redux";

import { ROUTES } from "src/constants";
import { ApplicationStore } from "src/store";
import { formatCurrency, formatDateTime } from "src/utils";

import {
  RowWrapper,
  Status,
  OrderNumber,
  Price,
  OrderDate,
  MoreLink,
  OrderWarehouse,
} from "./OrderRow.styles";
import { OrderRowProps } from "./OrderRow.types";

const OrderRow: FC<OrderRowProps> = ({
  orderId,
  orderDate,
  price,
  status,
  number,
  warehouse_sender,
}) => {
  const { userData } = useSelector((state: ApplicationStore) => state.user);
  return (
    <RowWrapper>
      <Status>{status}</Status>
      <OrderNumber>№ {userData?.is_retail ? number : orderId}</OrderNumber>
      {!userData?.is_retail && <Price>{formatCurrency(price)}</Price>}
      <OrderDate>{formatDateTime(orderDate)}</OrderDate>
      {userData?.is_retail && (
        <OrderWarehouse>Склад отгрузки: {warehouse_sender}</OrderWarehouse>
      )}
      <MoreLink to={`${ROUTES.myOrder}/${orderId}`}>Детали</MoreLink>
    </RowWrapper>
  );
};

export default OrderRow;
