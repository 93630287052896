import styled from "styled-components";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 400px;
`;

export const FallbackTitle = styled.h1`
  font-size: 36px;
  font-weight: 500;
`;

export const ErrorDescription = styled.p`
  font-size: 18px;
  font-weight: 400;
  & > span {
    font-weight: 500;
  }
`;
export const Phone = styled.a`
  font-size: 36px;
  line-height: 20px;
  color: ${({ theme }): string => theme.colors.black};
  margin: 0;
  text-decoration: none;
  white-space: nowrap;
  font-weight: 500;

  &:hover {
    color: ${({ theme }): string => theme.colors.grey};
  }
`;
