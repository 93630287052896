import React, { FC, useState } from "react";
import { useSelector } from "react-redux";

import { ApplicationStore } from "src/store";
import { formatCurrency } from "src/utils";

import {
  Wrapper,
  ImageContainer,
  Picture,
  Image,
  ParamsContainer,
  ParamsItemContainer,
  ProductNameLink,
  ProductSize,
  PriceContainer,
  OldPrice,
  Price,
  Count,
  CountLabel,
  PriceLabel,
  ImageLink,
} from "./OrderItem.styles";
import { OrderItemProps } from "./OrderItem.types";

const OrderItem: FC<OrderItemProps> = ({
  image,
  title,
  oldPrice,
  newPrice,
  count,
  size,
  imageBack,
  link,
  status,
}) => {
  const [imageUrl, setImageUrl] = useState(image);
  const { userData } = useSelector((state: ApplicationStore) => state.user);

  const handleMouseOver = () => {
    if (imageBack) {
      setImageUrl(imageBack);
    }
  };

  const handleMouseLeave = () => {
    setImageUrl(image);
  };
  return (
    <Wrapper>
      <ImageLink to={link}>
        <ImageContainer>
          <Picture>
            <source srcSet={imageUrl} media="(max-width: 200px)" />
            <Image
              onMouseOver={handleMouseOver}
              onMouseLeave={handleMouseLeave}
              onFocus={handleMouseOver}
              src={imageUrl}
              alt={title}
            />
          </Picture>
        </ImageContainer>
      </ImageLink>
      <ParamsContainer>
        <ParamsItemContainer>
          <ProductNameLink to={link}>{title}</ProductNameLink>
          {size && <ProductSize>Размер: {size}</ProductSize>}
        </ParamsItemContainer>
        <ParamsItemContainer>
          <PriceLabel>Цена</PriceLabel>

          <PriceContainer>
            {oldPrice > newPrice && (
              <OldPrice>{formatCurrency(oldPrice)}</OldPrice>
            )}
            <Price>{formatCurrency(newPrice)}</Price>
          </PriceContainer>
        </ParamsItemContainer>
        <ParamsItemContainer>
          <CountLabel>Количество</CountLabel>
          <Count>{count}</Count>
        </ParamsItemContainer>
        {userData?.is_retail && (
          <ParamsItemContainer>
            <CountLabel>Статус</CountLabel>
            <Count>{status}</Count>
          </ParamsItemContainer>
        )}
      </ParamsContainer>
    </Wrapper>
  );
};

export default OrderItem;
