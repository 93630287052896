import React, { FC } from "react";

const GarderoboIcon: FC = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3895 10.8573L20.4391 15.1161C21.3609 15.6038 21.0143 17 19.9715 17H1.62769C0.612174 17 0.243228 15.6616 1.11532 15.1412L10.7129 9.41484C11.8233 8.75228 13.0275 7.97876 13.3395 6.72386C14.1032 3.65224 8.73404 2.56704 8.73404 6.73622"
        stroke="black"
        stroke-opacity="0.8"
        stroke-width="1.2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default GarderoboIcon;
