import React, { FC, memo } from "react";

import { OrderInfo } from "../index";

import { ListContainer, OrderItems, StyledCheckoutItem } from "./styles";
import { OrderItemListProps } from "./types";

const OrderItemList: FC<OrderItemListProps> = memo(
  ({ checkout, deliveryPrice, bonusValue }) => {
    return (
      <ListContainer>
        <div className="sticky">
          <OrderItems>
            {checkout.items.map((item) => {
              const categories = item.section_tree
                ?.map((category) => category.name)
                .join("/");
              return (
                <StyledCheckoutItem
                  key={item.id}
                  item={item}
                  categories={categories}
                />
              );
            })}
          </OrderItems>
          <OrderInfo
            order={checkout}
            deliveryPrice={deliveryPrice}
            bonusValue={bonusValue}
          />
        </div>
      </ListContainer>
    );
  }
);

export default OrderItemList;
