import {
  parse,
  stringify,
  ParseOptions,
  StringifyOptions,
  StringifiableRecord,
} from "query-string";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Filter, FilterValue, SeoParams } from "src/types";

import { ROUTES } from "../../constants";

import { UseQueryParams } from "./useQueryParams.types";

type keyObjectFilterType = {
  advanced_color: string;
  gender_id: string;
  is_new: string;
  product_section: string;
  type_id: string;
  brand_id: string;
  is_sale: string;
};
const useQueryParams = (parseOptions?: ParseOptions): UseQueryParams => {
  const history = useHistory();
  const location = useLocation();

  const queryParams = useMemo(() => {
    const parsedParams = parse(location.search, parseOptions);
    const decodedParams = Object.fromEntries(
      Object.entries(parsedParams).map(([key, value]) => [
        decodeURIComponent(key),
        Array.isArray(value)
          ? value.map((item) => decodeURIComponent(item))
          : decodeURIComponent(value as string),
      ])
    );
    return decodedParams;
  }, [location.search, parseOptions]);

  const keyObjectFilter: keyObjectFilterType = {
    advanced_color: "cvet",
    gender_id: "gender",
    is_new: "new",
    product_section: "section",
    type_id: "type",
    brand_id: "brand",
    is_sale: "sale",
  };
  const arrayKeysGender = [
    "advanced_color",
    "product_section",
    "type_id",
    "brand_id",
    // "is_sale", // не работает на бэке
  ];
  const arrayKeysNew = [
    "gender_id",
    "is_new",
    "advanced_color",
    "product_section",
    "type_id",
    "brand_id",
  ];
  const arrayKeysSale = [
    "advanced_color",
    "gender_id",
    "product_section",
    "type_id",
    "brand_id",
    "is_sale",
  ];
  const arrayKeysType = [
    "advanced_color",
    "gender_id",
    "product_section",
    "type_id",
    "brand_id",
  ];
  const arrayKeysBrands = [
    "advanced_color",
    "gender_id",
    "product_section",
    "type_id",
    "is_sale",
  ];
  const arrayKeysInterior = [
    // "advanced_color", // не работает на бэке
    // "product_section", // не работает на бэке
    "is_sale",
  ];

  function getNewPath(pathName: string) {
    if (pathName.includes("filter-")) {
      const arrayOfPathName = pathName
        .split("/")
        .filter((i) => i.includes("filter-"))[0]
        .split("-");
      const keys = Object.keys(keyObjectFilter);
      const values = Object.values(keyObjectFilter);

      for (let i = 0; i < arrayOfPathName.length; i++) {
        const element = arrayOfPathName[i];
        const elementPrev = arrayOfPathName[i - 1];
        const isFilter = element === "filter";
        const isValuePrevSkidka = elementPrev === "sale";
        const isValuePrevNovinka = elementPrev === "new";
        const isValue = values.includes(element);
        const isValuePrev = values.includes(elementPrev);

        if (
          (!isFilter &&
            !isValue &&
            (isValuePrevSkidka || isValuePrevNovinka)) ||
          (!isFilter && !isValue && !isValuePrev)
        ) {
          history.push({
            pathname: ROUTES.page404,
          });
        }
      }

      const respObject: any = {}; //  eslint-disable-line @typescript-eslint/no-explicit-any
      keys.map((key) => {
        const storke = pathName.match(
          `${
            keyObjectFilter[key as keyof typeof keyObjectFilter]
          }-[a-z0-9_]{1,}`
        );
        if (storke) {
          respObject[key] = storke[0].split("-")[1];
        }
        if (pathName.includes("/new/")) {
          respObject.is_new = "true";
        }
        if (pathName.includes("/sale/")) {
          respObject.is_sale = "true";
        }
        return true;
      });
      return respObject;
    }
    return null;
  }

  function createUrl(
    clearPathName: string,
    filter: any //  eslint-disable-line @typescript-eslint/no-explicit-any
  ) {
    let newPathName = "filter";
    for (let i = 0; i < filter.length; i++) {
      newPathName += filter[i];
    }
    return newPathName.length > 6 ? clearPathName + newPathName + "/" : null;
  }

  function clearUrl() {
    const clearPath = window.location.pathname
      .split("/")
      .filter((i) => !i.includes("filter"))
      .join("/");
    return clearPath[clearPath.length - 1] === "/"
      ? clearPath
      : clearPath + "/";
  }

  function searchElFromCode(
    key: string,
    filters: Filter[] | undefined,
    id: string
  ) {
    if (filters) {
      const VALUES = filters.filter((i: Filter) => i.code === key)[0]?.values;
      const searchInURlCode = VALUES
        ? VALUES.filter((i: FilterValue) => i.id == id)[0] // eslint-disable-line eqeqeq
        : { code: "not-found" };
      return searchInURlCode;
    }
    return { code: "not-found" };
  }

  function getParameterFromUrl(
    pathname: string,
    params: any, // eslint-disable-line @typescript-eslint/no-explicit-any
    filters: any, // eslint-disable-line @typescript-eslint/no-explicit-any
    code: SeoParams
  ) {
    const urlParams: any = getNewPath(pathname) || {}; // eslint-disable-line @typescript-eslint/no-explicit-any
    const clearPathName = clearUrl();
    let arrayNewPath: any = []; // eslint-disable-line @typescript-eslint/no-explicit-any

    const path = window.location.pathname;
    const isGenderPage = path.includes("/man/") || path.includes("/woman/");
    const isTypePage = path.includes("/type/"); // ????
    const isBrandsPage = path.includes("/brands/");
    const isNewPage = path.includes("/new/");
    const isSalePage = path.includes("/sale/");
    const isInteriorPage = path.includes("/interior/");

    let currentLocation: any = []; // eslint-disable-line @typescript-eslint/no-explicit-any
    if (isGenderPage) currentLocation = arrayKeysGender;
    if (isTypePage) currentLocation = arrayKeysType;
    if (isBrandsPage) currentLocation = arrayKeysBrands;
    if (isNewPage) currentLocation = arrayKeysNew;
    if (isSalePage) currentLocation = arrayKeysSale;
    if (isInteriorPage) currentLocation = arrayKeysInterior;

    currentLocation.map((key: string) => {
      const filterKey = `filter[${key}]`;
      const urlParamKeyExists = urlParams && urlParams[key];
      const queryParamFilterKeyExists = queryParams && queryParams[filterKey];
      const paramFilterKeyExists = params && params[filterKey];
      const paramFilterKeyLengthIsOne =
        paramFilterKeyExists && params[filterKey].length === 1;
      const keyObjectFilterValue =
        keyObjectFilter[key as keyof typeof keyObjectFilter];
      if (
        (urlParamKeyExists &&
          !queryParamFilterKeyExists &&
          paramFilterKeyLengthIsOne) ||
        (!urlParamKeyExists &&
          queryParamFilterKeyExists &&
          paramFilterKeyLengthIsOne)
      ) {
        const searchInURlCode = searchElFromCode(
          key,
          filters,
          params[filterKey][0]
        );
        if (searchInURlCode?.code || urlParamKeyExists) {
          delete params[filterKey];
          arrayNewPath.push(
            `-${keyObjectFilterValue}-${
              searchInURlCode?.code !== "not-found"
                ? searchInURlCode.code
                : urlParams[key]
            }`
          );
        }
      } else if (
        code &&
        paramFilterKeyExists &&
        !queryParamFilterKeyExists &&
        paramFilterKeyLengthIsOne
      ) {
        delete params[filterKey];
        arrayNewPath.push(`-${keyObjectFilterValue}-${code[key]}`);
      } else if (
        !code &&
        urlParamKeyExists &&
        keyObjectFilterValue !== "new" &&
        keyObjectFilterValue !== "sale"
      ) {
        arrayNewPath.push(`-${keyObjectFilterValue}-${urlParams[key]}`);
      } else if (
        "page" in params &&
        params["page"] !== undefined &&
        urlParamKeyExists &&
        keyObjectFilterValue !== "new" &&
        keyObjectFilterValue !== "sale"
      ) {
        arrayNewPath.push(`-${keyObjectFilterValue}-${urlParams[key]}`);
      }
      if (
        (isNewPage && keyObjectFilterValue === "new") ||
        (keyObjectFilterValue === "new" && params[filterKey] !== "")
      ) {
        if (params[filterKey]) delete params[filterKey];
        arrayNewPath.push("-new");
      }
      if (
        (isSalePage && keyObjectFilterValue === "sale") ||
        (isBrandsPage &&
          keyObjectFilterValue === "sale" &&
          urlParamKeyExists &&
          params["page"] !== undefined) ||
        (keyObjectFilterValue === "sale" &&
          params[filterKey] &&
          params[filterKey] !== "")
      ) {
        if (params[filterKey]) delete params[filterKey];
        arrayNewPath.push("-sale");
      }
      return true;
    });

    if (
      (path.includes("/new") || path.includes("/sale")) &&
      arrayNewPath.length === 1 &&
      (arrayNewPath[0] === "-sale" || arrayNewPath[0] === "-new")
    ) {
      arrayNewPath = [];
    }
    return {
      pathname: createUrl(clearPathName, arrayNewPath) || clearPathName,
      newParams: params,
    };
  }

  const setQueryParams = useCallback(
    (
      newParams: StringifiableRecord,
      clear?: boolean,
      options?: StringifyOptions,
      pathname?: string,
      code?: SeoParams,
      filters?: any //  eslint-disable-line @typescript-eslint/no-explicit-any
    ) => {
      const qs = clear ? newParams : { ...queryParams, ...newParams };
      const getParameter = getParameterFromUrl(
        window.location.pathname,
        qs,
        filters,
        code || {}
      );
      const search = stringify(getParameter.newParams, options);
      history.push({
        pathname: clear ? clearUrl() : getParameter.pathname,
        search,
      });
    },
    [history, queryParams]
  );

  return { queryParams, setQueryParams, getNewPath, clearUrl };
};

export default useQueryParams;
