import React, { FC } from "react";

import {
  Labels,
  LabelItem,
  LabelItemBlack,
  LabelItemBlackSmall,
  LabelItemSale,
  LabelItemPromo,
  LabelItemSpring,
} from "./ProductLabel.styles";
import { ProductLabelProps } from "./ProductLabel.types";

export const ProductLabel: FC<ProductLabelProps> = (props) => {
  const { label, catalogClassName } = props;
  if (!label) {
    return <></>;
  }
  const labelComponents = {
    new: (
      <Labels className={catalogClassName}>
        <LabelItem className={catalogClassName}>new</LabelItem>
      </Labels>
    ),
    super: (
      <Labels className={catalogClassName}>
        <LabelItemSpring>spring</LabelItemSpring>
      </Labels>
    ),
    final: (
      <Labels className={catalogClassName}>
        <LabelItemBlack className={catalogClassName}>Final Sale</LabelItemBlack>
      </Labels>
    ),
    archive: (
      <Labels className={catalogClassName}>
        <LabelItemBlack className={catalogClassName}>
          archive sale
        </LabelItemBlack>
      </Labels>
    ),
    blacksale: (
      <Labels className={catalogClassName}>
        <LabelItemBlackSmall className={catalogClassName}>
          sale
        </LabelItemBlackSmall>
      </Labels>
    ),
    sale: (
      <Labels className={catalogClassName}>
        <LabelItemSale className={catalogClassName}>sale</LabelItemSale>
      </Labels>
    ),
    is_promo: (
      <Labels className={catalogClassName}>
        <LabelItemPromo className={catalogClassName}>-20%</LabelItemPromo>
      </Labels>
    ),
  };
  return labelComponents[label] || <></>;
};

export default ProductLabel;
