import React, { FC, useEffect, useRef } from "react";
import Slider from "react-slick";

import { Container, CustomCarousel } from "./Carousel.styles";
import { CarouselProps } from "./Carousel.types";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
};

const Carousel: FC<CarouselProps> = (props) => {
  const { className, children, disabled, ...rest } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<Slider>(null);
  let isScrolling = false;

  const handleTouchScroll = (e: WheelEvent) => {
    if (sliderRef.current && e.deltaX !== 0) {
      if (isScrolling) return;
      isScrolling = true;
      if (e.deltaX > 10) {
        sliderRef.current.slickNext();
      } else if (e.deltaX < -10) {
        sliderRef.current.slickPrev();
      }
      setTimeout(() => {
        isScrolling = false;
      }, 500);
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("wheel", handleTouchScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("wheel", handleTouchScroll);
      }
    };
  }, []);

  return (
    <Container ref={containerRef} className={className}>
      <CustomCarousel swipe={!disabled} ref={sliderRef} {...settings} {...rest}>
        {children}
      </CustomCarousel>
    </Container>
  );
};

export default Carousel;
