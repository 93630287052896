export const ROUTES = {
  home: "/",
  auth: "/auth/",
  login: "/auth/login/",
  register: "/auth/register/",
  reset: "/auth/reset/",
  resetByPhone: "/auth/reset/phone/",
  resetByEmail: "/auth/reset/email/",
  resetConfirmation: "/auth/reset/confirmation/",
  newPassword: "/auth/reset/new/",
  userInfo: "/auth/info/",
  checkout: "/checkout/",
  yapayresult: "/checkout/result",
  catalog: "/catalog/",
  manCatalog: "/catalog/man/",
  shareFavorites: "/catalog/share-favorites/",
  sertificateMan: "/catalog/man/sertifikaty/",
  sertificateWoman: "/catalog/woman/sertifikaty/",
  womanCatalog: "/catalog/woman/",
  saleCatalog: "/catalog/sale/",
  interiorCatalog: "/catalog/interior/",
  product: "/catalog/product/",
  brands: "/brands/",
  collections: "/collections/",
  shops: "/shops/",
  userPage: "/account/",
  editPersonalData: "/account/edit/",
  confirmationPersonalData: "/account/edit/confirmation/",
  editAddress: "/account/address/",
  newAddress: "/account/address/new/",
  return: "/about/return/",
  recommendations: "/recommendations/",
  vacancy: "/about/vacancies/",
  about: "/about",
  contacts: "/contacts/",
  privacy: "/privacy/",
  staticPage: "/static/",
  confirmationAuth: "/auth/confirmation/",
  delivery: "/about/shipping/",
  payment: "/about/payment/",
  discounts: "/discounts/",
  design: "/design/",
  favorites: "/favorites/",
  accountPage: "/my_account",
  personalData: "/my_account/personal_data",
  personalDataEdit: "/my_account/personal_data/edit",
  myOrder: "/my_account/my_order",
  myAddresses: "/my_account/my_addresses",
  addMyAddress: "/my_account/my_addresses/new",
  loyaltyCard: "/my_account/loyalty_card/",
  newsletters: "/my_account/newsletters/",
  newslettersFormEdit: "/my_account/newsletters/edit",
  bonusProgram: "/bonus-program/",
  magazinePage: "/magazine/",
  shortLinks: "/s",
  shortLinksSlash: "/s/",
  page404: "/page404/",
  personalDataCeate: "/personal/",
  outfits: "/outfits/",
  fallbackPage: "/fallback/",
};
