import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import CarouselNextArrow from "../../components/Carousel/CarouselNextArrow";
import CarouselPrevArrow from "../../components/Carousel/CarouselPrevArrow";

import {
  Container,
  CustomCarousel as MyCarousel,
} from "./CustomCarousel.styles";
import { CarouselProps } from "./CustomCarousel.types";

const CustomCarousel: FC<CarouselProps> = (props) => {
  const { className, children } = props;
  const carouselRef = useRef<HTMLDivElement>(null);
  const [disableLeftArrow, setDisableLeftArrow] = useState<boolean>(false);
  const [disableRightArrow, setDisableRightArrow] = useState<boolean>(false);

  const handleScrollRight = useCallback(() => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: -carouselRef.current.getBoundingClientRect().width - 20,
        behavior: "smooth",
      });
    }
  }, [carouselRef.current]);

  const handleScrollLeft = useCallback(() => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: carouselRef.current.getBoundingClientRect().width + 20,
        behavior: "smooth",
      });
    }
  }, [carouselRef.current]);

  const handleCheckScrollPosition = useCallback(() => {
    if (carouselRef.current) {
      setDisableLeftArrow(carouselRef.current.scrollLeft === 0);
      setDisableRightArrow(
        carouselRef.current.scrollLeft + carouselRef.current.clientWidth >=
          carouselRef.current.scrollWidth
      );
    }
  }, [carouselRef.current, children]);

  useEffect(() => {
    if (carouselRef.current) {
      handleCheckScrollPosition();
      carouselRef.current.addEventListener("scroll", handleCheckScrollPosition);
    }
    return () => {
      if (carouselRef.current)
        carouselRef.current.removeEventListener(
          "scroll",
          handleCheckScrollPosition
        );
    };
  }, [carouselRef.current, children]);

  return (
    <Container className={className}>
      <MyCarousel ref={carouselRef}>{children}</MyCarousel>
      {!disableRightArrow && <CarouselNextArrow onClick={handleScrollLeft} />}
      {!disableLeftArrow && (
        <CarouselPrevArrow
          onClick={handleScrollRight}
          slideCount={5}
          currentSlide={2}
        />
      )}
    </Container>
  );
};

export default CustomCarousel;
