import styled, { css } from "styled-components";

import { Box } from "../Box";
import { Flex } from "../Flex";
import { IconButton } from "../IconButton";
import { Icons } from "../Icons";
import { Text } from "../Text";

export const Container = styled(Flex)`
  padding: 12px 30px;
  flex-direction: column;

  &:first-child {
    @media screen and (max-width: 1150px) {
      margin-top: 10px;
    }
  }

  &:first-child::before {
    content: "";
    position: absolute;
    left: 30px;
    right: 30px;
    top: 0;
    border-top: 1px solid rgba(128, 128, 128, 0.1);
    width: calc(100% - 60px);
  }

  &::after {
    content: "";
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 0;
    border-bottom: 1px solid rgba(128, 128, 128, 0.1);
    width: calc(100% - 60px);
  }
`;

export const ContainerInner = styled(Flex)`
  justify-content: space-between;
  align-items: flex-start;
`;

export const Picture = styled.picture<{ disabled: boolean }>`
  height: 100%;
  min-height: 140px;
  width: 94px;
  min-width: 94px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `};
`;

export const Image = styled.img`
  display: block;
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

export const InfoContainer = styled(Box)`
  width: 100%;
  margin-right: 16px;
`;

export const InfoContainerInner = styled(Box)<{ disabled: boolean }>`
  width: 100%;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `};
`;

export const Name = styled(Text)`
  margin: 0;
  font-family: Formular, sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: ${({ theme }): string => theme.colors.black};
  text-decoration: none;

  a {
    font-family: Formular, sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: ${({ theme }): string => theme.colors.black};
    text-decoration: none;
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const Kind = styled(Text)`
  font-size: 14px;
  color: ${({ theme }): string => theme.colors.grey};
  margin: 8px 0 0;
`;

export const Size = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: ${({ theme }): string => theme.colors.black};
  margin: 24px 0 0;

  span {
    font-size: 8px;
    color: ${({ theme }): string => theme.colors.grey};
    vertical-align: middle;
  }
`;

export const Price = styled(Text)`
  font-size: 20px;
  color: ${({ theme }): string => theme.colors.black};
  margin: 0;
`;

export const DeleteButton = styled.button`
  position: relative;

  z-index: 2;

  width: 55px;

  padding: 0;
  margin: 17px 0 0;
  border: none;
  background-color: inherit;
  cursor: pointer;
  font-size: 12px;
  color: ${({ theme }): string => theme.colors.red};

  text-align: left;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const OldPrice = styled(Price)`
  color: ${({ theme }): string => theme.colors.grey};
  text-decoration: line-through;
  margin-right: 12px;
  display: flex;
  align-items: flex-end;
  font-size: 18px;
`;

export const PriceContainer = styled(Flex)`
  margin: 3px 0 2px;
  flex-wrap: wrap;
`;

export const ProductCount = styled(Flex)`
  align-items: center;
  margin-right: 18px;
`;

export const CountButton = styled(IconButton)`
  color: ${({ theme }): string => theme.colors.black};
  padding: 0;

  &:hover {
    opacity: 0.5;
  }

  &:disabled {
    svg {
      fill: ${({ theme }): string => theme.colors.lightGrey};
    }
  }
`;

export const MinusIcon = styled(Icons.Minus)`
  width: 15px;
  height: 15px;
`;

export const PlusIcon = styled(Icons.Plus)`
  width: 15px;
  height: 15px;
`;

export const Count = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }): string => theme.colors.grey};
  margin: 0 5px;
`;

export const CountTitle = styled.p`
  padding-top: 2px;
`;

export const Quantity = styled.div`
  text-align: right;
  color: ${({ theme }): string => theme.colors.grey};
  margin: 10px 0 -10px;
`;

export const ProductNotAvailable = styled.div`
  position: relative;

  display: flex;

  align-items: center;

  z-index: 2;

  margin-bottom: 17px;

  font-size: 12px;
  line-height: 15px;

  letter-spacing: 0.01em;

  color: ${({ theme }): string => theme.colors.red};

  p {
    margin: 0;
  }
`;

export const ProductNotAvailableIcon = styled.div`
  display: inline-block;

  min-width: 14px;
  min-height: 14px;

  margin-right: 8px;

  border-radius: 50%;

  text-align: center;
  vertical-align: middle;

  background: ${({ theme }): string => theme.colors.red};
  color: ${({ theme }): string => theme.colors.white};
`;
