import React, { FC, useEffect, useState } from "react";

import {
  CloseButton,
  CloseIcon,
  ModalContainer,
  Wrapper,
  Title,
  AddButton,
  SizeContainer,
  SizeButton,
} from "./AddToFavorites.styles";
import { AddToFavoritesProps } from "./AddToFavorites.types";

const AddToFavorites: FC<AddToFavoritesProps> = ({
  productId,
  offers,
  onClose,
  addItemToFavorites,
}) => {
  const [activeOffer, setActiveOffer] = useState(0);

  const realOffers = offers.filter((offer) => offer.available);

  const toggleActive = (offerId: number) => {
    setActiveOffer(offerId);
  };

  const addToFavoritesHandler = () => {
    addItemToFavorites(productId, activeOffer);
    onClose();
  };

  useEffect(() => {
    if (realOffers.length >= 1) {
      setActiveOffer(realOffers[0].id);
    }
  }, []);

  return (
    <ModalContainer onClose={onClose}>
      <CloseButton onClick={onClose} aria-label="Закрыть">
        <CloseIcon />
      </CloseButton>

      <Wrapper>
        <div>
          <Title>Выберите размер</Title>
          <SizeContainer>
            {realOffers.map(
              (offer) =>
                offer.available && (
                  <SizeButton
                    onClick={() => toggleActive(offer.id)}
                    isActive={activeOffer === offer.id}
                    key={offer.id}
                  >
                    {offer.size_name}
                  </SizeButton>
                )
            )}
          </SizeContainer>
        </div>

        <AddButton onClick={addToFavoritesHandler} disabled={activeOffer === 0}>
          Добавить в избранное
        </AddButton>
      </Wrapper>
    </ModalContainer>
  );
};

export default AddToFavorites;
