import React, { FC, memo, useState } from "react";

import { FavoritesItem } from "src/components";
import { FavoriteItem } from "src/types";

import { Container } from "./FavoritesList.styles";
import { FavoritesListProps } from "./FavoritesList.types";

const FavoritesList: FC<FavoritesListProps> = memo(
  ({ items, removeFavorite }) => {
    const [clickedMobileID, setClickedMobileID] = useState<number | null>(null);
    const selectMobileItem = (id: number | null) => {
      setClickedMobileID(id);
    };
    return (
      <Container>
        {items.map((favorite: FavoriteItem) => (
          <FavoritesItem
            key={favorite.id}
            favorite={favorite}
            removeFavorite={removeFavorite}
            offers={favorite.offers}
            selectedItemID={clickedMobileID}
            onClickedMobileID={selectMobileItem}
          />
        ))}
      </Container>
    );
  }
);

export default FavoritesList;
