import React, { FC, useEffect, useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { getOrdersUser, MyOrdersType } from "src/api";
import { SortingModal, OrderRow, Preloader } from "src/components";
import { SelectOption } from "src/components/Select/Select.types";
import { ROUTES, stringifyConfig } from "src/constants";
import { useControl, useQueryParams } from "src/hooks";
import { usePreloaderTimer } from "src/hooks/usePreloaderTimer";
import { ApplicationStore } from "src/store";

import {
  Title,
  EmptyOrdersList,
  CatalogLink,
  MobileFilterButton,
  OrderHeader,
  BackLink,
  BackIcon,
  BackIconMobile,
  TitleWrapper,
  Loader,
} from "./MyOrders.styles";

const initialSortingOption = {
  value: undefined,
  label: "Все заказы",
};

const MyOrders: FC = () => {
  const { search } = useLocation();

  const { userData } = useSelector((state: ApplicationStore) => state.user);

  const {
    state: isOrdersSortingModalOpened,
    deactivate: closeOrdersSortingModal,
    activate: openOrdersSortingModal,
  } = useControl();

  const [orders, setOrders] = useState<MyOrdersType>({ orders: [] });

  const [isLoaderShown, onStartFetching, onEndFetching] = usePreloaderTimer();

  const { setQueryParams } = useQueryParams({
    arrayFormat: "bracket",
  });

  const sortingItems = useMemo(() => orders?.filter_settings?.status_id, [
    orders,
  ]);

  const [isLoading, setLoading] = useState(false);
  const [sorting, setSorting] = useState<SelectOption>(initialSortingOption);

  const handleChangeSorting = useCallback(
    (option: SelectOption) => {
      setQueryParams(
        { status_id: option.value || undefined },
        true,
        stringifyConfig
      );
      setSorting(option);
    },
    [setQueryParams]
  );

  useEffect(() => {
    setLoading(true);
    if (userData?.userId) {
      onStartFetching();
      getOrdersUser(userData.userId, sorting.value)
        .then(({ data }) => {
          setOrders(data);
        })
        .finally(() => {
          setLoading(false);
          onEndFetching();
        });
    }
  }, [userData, search, sorting, onStartFetching, onEndFetching]);

  return (
    <>
      {isLoaderShown && <Preloader />}

      <OrderHeader>
        <BackLink to={ROUTES.accountPage}>
          <BackIcon />
          <BackIconMobile />
          <span>Назад</span>
        </BackLink>
        <TitleWrapper>
          <Title>Мои заказы</Title>
          <MobileFilterButton variant="white" onClick={openOrdersSortingModal}>
            Сортировка
          </MobileFilterButton>
        </TitleWrapper>
        {/* TODO: временно скрываем сортировку по статусу заказа
        
        {sortingItems && (
          <div>
            <SelectContainer>
              {sortingItems.length > 0 && (
                <Select
                  options={sortingItems}
                  value={sorting}
                  onChange={handleChangeSorting}
                />
              )}
            </SelectContainer>
          </div>
        )} */}
      </OrderHeader>
      {!isLoading ? (
        <>
          {orders.orders.length ? (
            orders.orders.map((order) => (
              <OrderRow
                key={order.id}
                status={order.status_name}
                orderId={order.id}
                number={order.number}
                price={order.result_price}
                orderDate={order.created}
                warehouse_sender={order.warehouse_sender}
              />
            ))
          ) : (
            <EmptyOrdersList>
              <p>На данный момент у вас нет заказов</p>
              <CatalogLink variant="black" to={ROUTES.catalog}>
                Перейти к покупкам
              </CatalogLink>
            </EmptyOrdersList>
          )}
        </>
      ) : (
        <Loader>
          <div />
          <div />
          <div />
        </Loader>
      )}

      {sortingItems && isOrdersSortingModalOpened && (
        <SortingModal
          onClose={closeOrdersSortingModal}
          isLoading={false}
          onChangeSorting={handleChangeSorting}
          sortingItems={sortingItems}
          sortingValue={sorting}
        />
      )}
    </>
  );
};

export default MyOrders;
