import React, { FC } from "react";

import { FallbackTitle, PageWrapper, Phone } from "./FallbackPage.styles";

const FallbackPage: FC = () => {
  return (
    <PageWrapper>
      <FallbackTitle>
        Пожалуйста, сообщите об ошибке по номеру{" "}
        <Phone href="tel:+7 800 775-19-70" rel="noopener noreferrer">
          +7 800 775-19-70
        </Phone>{" "}
        или нам в чат.
      </FallbackTitle>
    </PageWrapper>
  );
};

export default FallbackPage;
