import React, { FC, useEffect, useMemo, useState } from "react";
import TagManager from "react-gtm-module";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useParams, useLocation } from "react-router";

import { getMagazinePage } from "src/api";
import { Preloader } from "src/components";
import {CDN_ENDPOINT} from "src/constants";
import { usePreloaderTimer } from "src/hooks/usePreloaderTimer";
import { SeoMeta } from "src/types";
import { generateMetaTags } from "src/utils";

import { IFrame } from "./MagazineItemPage.styles";

const MagazineItemPage: FC = () => {
  const search = useLocation();
  let { code } = useParams<{ code: string }>();
  const [seoTitle, setSeoTitle] = useState("LEFORM");
  const [seoDescription, setSeoDescription] = useState("LEFORM");
  const [isLoaderShown, onStartFetching, onEndFetching] = usePreloaderTimer();

  if (search.pathname.indexOf("bonus-program") !== -1) {
    code = "bonus-program";
  } else if (search.pathname.indexOf("about") !== -1) {
    code = "about";
  }

  useEffect(() => {
    if (code !== "bonus-program") {
      getMagazinePage(code).then(({ data }) => {
        data.item.name_old && setSeoTitle(data.item.name_old);
        data.item.preview_text_old &&
          setSeoDescription(data.item.preview_text_old);
      });
    } else if (code === "bonus-program") {
      setSeoTitle("Бонусная программа");
      setSeoDescription(
        "Бонусная Программа. Leform: концептуальный интернет-магазин одежды, обуви, аксессуаров и предметов интерьера"
      );
    }
  }, [code]);

  const metaTags: SeoMeta[] = useMemo(() => {
    const meta: SeoMeta[] = generateMetaTags(seoTitle, seoDescription);
    return meta;
  }, [seoTitle, seoDescription]);

  useEffect(() => {
    onStartFetching(0);
    document.body.style.overflow = "hidden";
    document.body.style.marginTop = "70px";

    return (): void => {
      document.body.style.overflow = "auto";
      document.body.style.marginTop = "";
    };
  }, []);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
      },
    });
  }, []);

  const readymagUrls = ["new-year-prize", "100-community"];

  return (
    <>
      <HelmetProvider>
        <Helmet defer={false} title={seoTitle} meta={metaTags} />
      </HelmetProvider>

      {isLoaderShown && <Preloader />}

      <IFrame
        src={
          readymagUrls.includes(code)
            ? `https://readymag.com/leform/${code}`
            : `${CDN_ENDPOINT}/magazine/${code}/`
        }
        title={`${code}`}
        onLoad={(event) => onEndFetching(500)}
      />
    </>
  );
};

export default MagazineItemPage;
