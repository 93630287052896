import React, { FC, useEffect, useState } from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { useHistory, useParams } from "react-router-dom";

import { getFavoritesListByUserId } from "src/api";
import { CatalogList, Preloader } from "src/components";
import { ROUTES } from "src/constants";
import { GetIdForShareData, SeoMeta, ShareCatalogData } from "src/types";

import { Container } from "./ShareFavorites.styles";

const ShareFavoritesPage: FC = () => {
  const metaTags: SeoMeta[] = [
    { name: "robots", content: "noindex, nofollow" },
  ];

  const { id } = useParams<GetIdForShareData>();
  const [catalogData, setCatalogData] = useState<ShareCatalogData>();
  const [isLoading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState<SeoMeta[]>(metaTags);
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    getFavoritesListByUserId(id)
      .then(({ data }) => {
        setCatalogData(data);
      })
      .catch((error) => {
        const userAgent = navigator.userAgent;
        const isPrerender = userAgent.includes("prerender");
        const errorCode = error?.response?.status;
        if (!isPrerender && errorCode === 404) {
          history.push(ROUTES.page404);
        }
        const newArr = [...metaData];

        const hasRedirect = newArr.find(
          (meta) => meta.name === "prerender-status-code"
        );
        if (!hasRedirect) {
          newArr.push({
            name: "prerender-status-code",
            content: "404",
          });
          newArr.push({
            name: "prerender-header",
            content: ROUTES.catalog,
          });
          setMetaData(newArr);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {isLoading && <Preloader />}
      <HelmetProvider>
        <Helmet defer={false} meta={metaData} />
      </HelmetProvider>
      <Container>
        <CatalogList data={catalogData} isPaginationVisible={false} />
      </Container>
    </>
  );
};

export default ShareFavoritesPage;
